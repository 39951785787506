import { ElementRef, Injectable, Injector } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AcnConnectorService, IRequest } from '@acn/angular';
import {
  CitiesStoreService,
  ContactStoreService,
  ICity,
  IInstallation,
  StepStoreService,
} from '@store/dashboard';

import { SelectCityService } from '@features/select-city/services/select-city.service';
import { IAttendanceAgencies } from '@store/dashboard/states/cities/models/attendance-agency.model';
import { IUserInfo } from '@shared/models/user-info.model';
import { BaseService } from '@shared/services/base/base.service';
import { IFaq, IFaqQuestion } from '@features/attendance/models/faq.model';
import { AttendanceStoreService } from '@store/app';
import { ICityContacts } from '../models/Contacts.model';
import { IContactResponse } from './../dto/response/Contact.response';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public breakingNewsNotice$ = this._citiesStore.breakingNewsNotice$;
  public notSelectedCity$ = this._citiesStore.notSelectedCity$;
  public faqState$ = this._attendanceStore.faqState$;
  public lackOfWater$ = this._citiesStore.waterShortageAlert$;
  public selectedCityServices$ = this._selectCityService.selectedCityServices$;
  public useDefaultAttendancePlatform$ = this._attendanceStore.useDefaultAttendancePlatform$;

  private readonly CONTACT_URL = 'servicos/v2/solicitarContato';
  private readonly CONTACT_URL_LOGGED = 'servicos/solicitarContatoLogado';
  private readonly AGENCIES_URL = 'atendimento/v2/agencias';
  private readonly FAQ_URL = 'atendimento/faq';
  private readonly CONTACTS_URL = 'atendimento/contato/cidade/:idCidade';

  constructor (
    private _citiesStore: CitiesStoreService,
    private _connectorApi: AcnConnectorService,
    private _stepStore: StepStoreService,
    private _router: Router,
    private _contactStore: ContactStoreService,
    private _store: Store,
    private _selectCityService: SelectCityService,
    protected _injector: Injector,
    private _attendanceStore: AttendanceStoreService,
  ) {
    super(_injector);
  }

  getCitiesList () {
    return this._selectCityService.getCity();
  }

  resetContactState () {
    this._contactStore.resetContact();
    this._stepStore.resetSteps();
  }

  setName (name: string) {
    this._contactStore.setContactName(name);
  }

  getName () {
    return this._contactStore.getContactName();
  }

  setCdc (cdc: string) {
    this._contactStore.setContactCdc(cdc);
  }

  getCdc () {
    return this._contactStore.getContactCdc();
  }

  setCity (city: string) {
    this._contactStore.setContactCity(city);
  }

  getCity () {
    return this._contactStore.getContactCity();
  }

  resetFaq () {
    this._attendanceStore.resetFaq();
  }

  setEmail (email: string) {
    this._contactStore.setContactEmail(email);
  }

  getEmail () {
    return this._contactStore.getContactEmail();
  }

  setPhone (phone: string) {
    this._contactStore.setContactPhone(phone);
  }

  gePhone () {
    return this._contactStore.getContactPhone();
  }

  scrollTop (elementRef: ElementRef) {
    this._screenService.scrollTop(elementRef);
  }

  sendMessage (message: string, recaptcha?: string) {
    return combineLatest([this._contactStore.contactState$, this.selectedCity$]).pipe(
      switchMap(([state, selectedCity]) => {
        const body = {
          email: state.email,
          nome: state.name,
          telefone: state.phone,
          cdc: state.cdc,
          descricao: message,
          tokenRecaptcha: recaptcha,
        };
        if (this.onDashboard()) {
          delete body.tokenRecaptcha;
        }
        const req: IRequest = {
          endPoint: this.onDashboard() ? this.CONTACT_URL_LOGGED : this.CONTACT_URL,
          showLoading: true,
          checkToken: false,
          queryString: { idCidade: selectedCity?.id || state.city },
          body: body,
        };

        return this._connectorApi.post(req);
      }),
      switchMap((res) => {
        this.showModal({
          componentId: 'attendance.completed',
          canClose: false,
        });

        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getAgencies (cityId: number) {
    const req: IRequest = {
      endPoint: this.AGENCIES_URL,
      checkToken: false,
      showLoading: true,
      queryString: { idCidade: cityId },
    };

    return this._connectorApi.get<Array<IAttendanceAgencies>>(req);
  }

  getContactsOfCity (idCity: number): Observable<ICityContacts | null> {
    const url = this.CONTACTS_URL.replace(':idCidade', `${idCity}`);
    const request: IRequest = {
      endPoint: url,
      checkToken: false,
      showLoading: true,
    };

    return this._connectorApi.get(request).pipe(
      map((response: IContactResponse) => {
        if (response.id === null) {
          return null;
        }

        return {
          id: response.id,
          idCity: response.idCidade,
          whatsapp: response.whatsapp,
          whatsappHours: response.whatsappHorario,
          whatsappNote: response.whatsappNota,
        };
      }),
    );
  }

  getBreakingNewsNotice (cityId) {
    this._selectCityService.getBreakingNews(cityId);
  }

  getFaq (cityId: number) {
    const req: IRequest = {
      endPoint: this.FAQ_URL,
      checkToken: false,
      showLoading: true,
      queryString: { idCidade: cityId },
    };

    return this._connectorApi.get<IFaq>(req);
  }

  getWaterShortageAlert (): string {
    return this._citiesStore.getWaterShortageAlert();
  }

  setSteps () {
    let steps = ['', 'cidade', 'email', 'telefone', 'cdc', 'mensagem'];
    const selectedCity: ICity = this._store.selectSnapshot(
      (state) => state.dashboard.cities.selectedCity,
    );

    if (selectedCity && selectedCity.isAttended) {
      steps = ['', 'email', 'telefone', 'cdc', 'mensagem'];
      this.setCity(selectedCity.id.toString());
    }

    if (this.onDashboard()) {
      steps = ['email', 'mensagem'];
      this._setUserContact();
    }

    this._stepStore.setSteps(steps);
  }

  setCurrentStep (step: number) {
    this._stepStore.setCurrentStep(step);
  }

  /**
   * Set previous user info when logged
   */
  private _setUserContact () {
    const userInfo: IUserInfo = this._store.selectSnapshot((state) => state.app.user.userInfo);
    this.setName(userInfo.nome);
    this.setPhone(userInfo.telMovel);
    this.setEmail(userInfo.email);

    const installation: IInstallation = this._store.selectSnapshot(
      (state) => state.dashboard.installation.selected,
    );
    this.setCdc(installation.cdc);
  }

  onDashboard () {
    return this._settingsStore.onDashboard();
  }

  navigateToStep (step: string) {
    this.setStepById(step);
    const base = this.onDashboard() ? 'home-logado' : 'home';
    this._router.navigate([`${base}/atendimento/contato/${step}`]);
  }

  setStepById (step: string) {
    this._stepStore.navigateToStep(step);
  }

  nextStep () {
    const nextStep = this._stepStore.nextStep();
    const base = this.onDashboard() ? 'home-logado' : 'home';

    if (nextStep !== null) {
      this._router.navigate([`${base}/atendimento/contato/${nextStep}`]);
    }
  }

  prevStep () {
    const prevStep = this._stepStore.prevStep();
    const base = this.onDashboard() ? 'home-logado' : 'home';

    if (prevStep !== null) {
      this._router.navigate([`${base}/atendimento/contato/${prevStep}`]);
      return;
    }

    this._router.navigate([`/${base}/atendimento`]);
  }

  navigate (url: string) {
    this._router.navigate([url]);
  }

  setFaq (faq: IFaq) {
    this._attendanceStore.setFaq(faq);
  }

  setUseDefaultAttendancePlatform (useDefault: boolean) {
    this._attendanceStore.setDefaultAttendancePlatform(useDefault);
  }

  setFaqQuestion (question: IFaqQuestion) {
    this._attendanceStore.setFaqQuestion(question);
  }
}
