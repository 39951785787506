export const formatPhone = (phone: string): string => {
    if (!phone) {
      return '';
    }
  
    const phoneNumbers = phone.replace(/\D/g, '');
    const phoneLength = phoneNumbers.length;
  
    if (phoneLength === 11) {
      return phoneNumbers.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
  
    if (phoneLength === 10) {
      return phoneNumbers.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
  
    if (phoneLength === 9) {
      return phoneNumbers.replace(/^(\d{5})(\d{4})/, '$1-$2');
    }
  
    if (phoneLength === 8) {
      return phoneNumbers.replace(/^(\d{4})(\d{4})/, '$1-$2');
    }
  
    return phone;
  };
  