import { IPix } from '@features/duplicate/services/models/pix.model';
import { DuplicateDetail, GroupedDuplicate, IDuplicate } from '@features/easy-duplicate';
import { ICity } from '@store/dashboard';

export class Reset {
  public static readonly type = '[EASY DUPLICATE] Reset initial state';
}

export class SetOwner {
  public static readonly type = '[EASY DUPLICATE] Set owner';
  constructor(public payload: boolean) {}
}

export class SetDocType {
  public static readonly type = '[EASY DUPLICATE] Set type of document used to get debts';
  constructor(public payload: string) {}
}

export class SetDocument {
  public static readonly type = '[EASY DUPLICATE] Set document used to get debts';
  constructor(public payload: string) {}
}

export class SetPhone {
  public static readonly type = '[EASY DUPLICATE] Set phone used to get debts';
  constructor(public payload: string) {}
}

export class SetCity {
  public static readonly type = '[EASY DUPLICATE] Set city selected when searching for debts';
  constructor(public payload: ICity) {}
}

export class SetEasyDuplicateDoc {
  public static readonly type = '[EASY DUPLICATE] Set duplicates retrieved with document id';
  constructor(public payload: Array<IDuplicate>) {}
}

export class SetEasyDuplicateCdc {
  public static readonly type = '[EASY DUPLICATE] Set duplicates retrieved with cdc code';
  constructor(public payload: IDuplicate) {}
}

export class SetIsGrouped {
  public static readonly type = '[EASY DUPLICATE] Set the selection that the user wants to keep';
  constructor(public payload: boolean) {}
}

export class SetSelectedDuplicate {
  public static readonly type = '[EASY DUPLICATE] Set duplicate generated by selected id';
  constructor(public payload: DuplicateDetail) {}
}

export class SetGroupedDuplicate {
  public static readonly type = '[EASY DUPLICATE] Set grouped duplicate generated by selected id';
  constructor(public payload: GroupedDuplicate) {}
}

export class SetPixData {
  public static readonly type = '[EASY DUPLICATE] Set Pix api data';
  constructor(public payload: IPix) {}
}
